import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }


  get(key: string) {
    const x = localStorage.getItem(key);
    if (x) {
      return JSON.parse(x);
    }
    return null;
  }

  set(key: string, value: Object) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }


}
