import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  _isLoggedIn = false;
  authKey = 'token';

  isAuthenticated() {
    return this._isLoggedIn;
  }

  constructor(private api: ApiService, private storage: StorageService) {
    this.token = this.storage.get(this.authKey) || '';
    this._isLoggedIn = !!this.token;
  }


  async login(credentials: { email: string | null, password: string | null }) {
    try {

      const result: any = await lastValueFrom(this.api.post('auth/login', credentials));
      this.token = result.data.token;
      this.storage.set(this.authKey, this.token);
      this._isLoggedIn = true;
      return result;
    } catch (error) {
      throw error;
    }
  }


  async register(credentials: { name: string | null, email: string | null, password: string | null }) {
    return lastValueFrom(this.api.post('auth/register', credentials));;
  }



  async verifyEmail(token: string) {
    const result: any = await lastValueFrom(this.api.post('auth/verify-email', { token }));
    return result;
  }


  async resetPassword(email: string) {
    const result: any = await lastValueFrom(this.api.post('auth/reset-password', { email }));
    return result;
  }

  async changePassword(token: string, password: string) {
    return lastValueFrom(this.api.post('auth/change-password', { token, password }));
  }

  async changePasswordInvite(token: string, password: string) {
    return lastValueFrom(this.api.post('auth/invite-change-password', { token, password }));
  }



  closeSession() {
    this.storage.remove(this.authKey);
    this.token = '';
    this._isLoggedIn = false;


  }

}
